import React from "react"
import {
	ContactContainer,
	ContactWrapper,
	ContactRow,
	BtnWrap,
	Column1,
	Column2,
	Heading,
	ImgWrap,
	Subtitle,
	TextWrapper,
	TopLine,
	Img,
} from "../Contact/ContactElements"

import { Button } from "../../Utilities/ButtonElements"

const ContactSection = ({
	lightBg,
	imgStart,
	topLine,
	lightText,
	darkText,
	headline,
	description,
	buttonLabel,
	showButton,
	alt,
	img,
	id,
	primary,
	dark,
	dark2,
}) => {
	function mailTo() {
		// Encode subject line to handle special characters
		const recipientEmail = "laura.geng@w3i.foundation"
		// const emailSubject = "Website Inquiry at W3I Investment"
		// const emailBody =
		// 	"Hi W3I Investments, I am writing to inquire about private investment opportunities. \n\n Name: \nPhone Number: \nAge:"

		// const encodedSubject = encodeURIComponent(emailSubject)
		// const encodedEmailBody = encodeURIComponent(emailBody)
		// const mailtoLink = `mailto:${recipientEmail}?subject=${encodedSubject}&body=${encodedEmailBody}`
		const mailtoLink = `mailto:${recipientEmail}`

		window.open(mailtoLink)
	}

	return (
		<>
			<ContactContainer lightBg={lightBg} id={id}>
				<ContactWrapper>
					<ContactRow imgStart={imgStart}>
						<Column1>
							<TextWrapper>
								<TopLine>{topLine}</TopLine>
								<Heading lightText={lightText}>
									{headline}
								</Heading>
								<Subtitle darkText={darkText}>
									{description}
								</Subtitle>
								<br />
								{showButton && (
									<BtnWrap>
										<Button onClick={mailTo}
											to="/"
											smooth={true}
											duration={500}
											spy={true}
											exact={true}
											offset={-80}
											primary={primary ? 1 : 0}
											dark={dark ? 1 : 0}
											dark2={dark2 ? 1 : 0}
										>
											{buttonLabel}
										</Button>
									</BtnWrap>
								)}
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<Img src={img} alt={alt} />
							</ImgWrap>
						</Column2>
					</ContactRow>
				</ContactWrapper>
			</ContactContainer>
		</>
	)
}

export default ContactSection
