import React, { useState } from "react"
import {
	HeroContainer,
	HeroBg,
	VideoBg,
	ArrowForward,
	HeroContent,
	HeroP,
	HeroTitle,
	HeroBtnWrapper,
	ArrowRight,
} from "../Hero/HeroElements"
import { Button } from "../../Utilities/ButtonElements"
import Video from "../../Videos/video-blue.mp4"

const Hero = () => {
	const [hover, setHover] = useState(false)

	const onHover = () => {
		setHover(!hover)
	}

	return (
    <>
      <HeroContainer>
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </HeroBg>
        <HeroContent>
          <HeroTitle>Investing in Tomorrow's Success</HeroTitle>
          <HeroP>
            At W3l Investments, we specialise in sustainable wealth growth by
            identifying and nurturing top investment opportunities. Our team’s
            unparalleled expertise ensures meticulous and successful investments
            across diverse sectors.
          </HeroP>
        </HeroContent>
      </HeroContainer>
    </>
  );
}

export default Hero
