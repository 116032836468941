import React from "react"
import {
	FooterContainer,
	FooterLink,
	FooterLinkContainer,
	FooterLinkItems,
	FooterLinkTitle,
	FooterLinkWrapper,
	FooterWrap,
	SocialIconLink,
	SocialIcons,
	SocialLogo,
	SocialMedia,
	SocialMediaWrap,
	WebsiteRights,
} from "./FooterElements"
import { FaXTwitter, FaYoutube } from "react-icons/fa6"
import { animateScroll as scroll } from "react-scroll"

const Footer = () => {
	const toggleHome = () => {
		scroll.scrollToTop()
	}

	return (
		<>
			<FooterContainer>
				<FooterWrap>
					<FooterLinkContainer>
						<FooterLinkWrapper>
							<FooterLinkItems>
								<FooterLinkTitle>
									<p>
										All investments carry risks, and it is
										important to conduct your own research
										and seek professional advice before
										making any investment decisions. The
										information provided on this website is
										for informational purposes only and does
										not constitute investment advice.
									</p>
									<br />
									<p>
										This content is designed to instill
										confidence in potential investors and
										partners by highlighting the company’s
										mission, values, expertise, strategic
										partnerships, and the specific asset
										classes that W3I Investments Pte Ltd
										focuses on.
									</p>
								</FooterLinkTitle>
							</FooterLinkItems>
							{/* <FooterLinkItems>
                                <FooterLinkTitle>Videos</FooterLinkTitle>
                                <FooterLink to="/">Submit Video</FooterLink>
                                <FooterLink to="/">Ambassador</FooterLink>
                                <FooterLink to="/">Agency</FooterLink>
                                <FooterLink to="/">Influencer</FooterLink>
                            </FooterLinkItems>
                        </FooterLinkWrapper>
                        <FooterLinkWrapper>
                            <FooterLinkItems>
                                <FooterLinkTitle>Contact Us</FooterLinkTitle>
                                <FooterLink to="/">Contact</FooterLink>
                                <FooterLink to="/">Support</FooterLink>
                                <FooterLink to="/">Sponsorship</FooterLink>
                            </FooterLinkItems>
                            <FooterLinkItems>
                                <FooterLinkTitle>Business</FooterLinkTitle>
                                <FooterLink to="/">Blog</FooterLink>
                                <FooterLink to="/">Referral Program</FooterLink>
                                <FooterLink to="/">Media Assets</FooterLink>
                                <FooterLink to="/">Security</FooterLink>
                            </FooterLinkItems> */}
						</FooterLinkWrapper>
					</FooterLinkContainer>
					<SocialMedia>
						<SocialMediaWrap>
							<SocialLogo to="/" onClick={toggleHome}>
								W3I Investments Pte Ltd
							</SocialLogo>
							<WebsiteRights>
								{new Date().getFullYear()} All rights reserved.
							</WebsiteRights>
							<SocialIcons>
								<SocialIconLink
									href="https://www.youtube.com/@W3IInvestments"
									target="_blank"
									aria-label="Youtube"
								>
									<FaYoutube />
								</SocialIconLink>
								<SocialIconLink
									href="https://x.com/W3iFoundation"
									target="_blank"
									aria-label="X"
								>
									<FaXTwitter />
								</SocialIconLink>
							</SocialIcons>
						</SocialMediaWrap>
					</SocialMedia>
				</FooterWrap>
			</FooterContainer>
		</>
	)
}

export default Footer
