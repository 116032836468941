import React from "react"
import {
	ServicesContainer,
	ServicesCard,
	ServicesH1,
	ServicesH2,
	ServicesP,
	ServicesWrapper,
	ServicesIcon,
} from "./ServiceElements"
import expertiseImg from "../../Images/expertise.svg"
import supportImg from "../../Images/support.svg"
import networkImg from "../../Images/network.svg"
import performanceImg from "../../Images/performance.svg"

const Services = () => {
	return (
		<>
			<ServicesContainer id="services">
				<ServicesH1>Why Choose Us</ServicesH1>
				<ServicesWrapper>
					<ServicesCard>
						<ServicesIcon src={expertiseImg} />
						<ServicesH2>Expert Industry Knowledge</ServicesH2>
						<ServicesP>
							Our team consists of industry veterans with
							extensive experience and a stellar track record.
						</ServicesP>
					</ServicesCard>
					<ServicesCard>
						<ServicesIcon src={networkImg} />
						<ServicesH2>Expansive Network</ServicesH2>
						<ServicesP>
							Through our partnerships with Finapac Capital,
							Greythorn Management, and Hatchstone Capital, we
							provide access to a vast network of industry
							contacts, investors, and resources.
						</ServicesP>
					</ServicesCard>
					<ServicesCard>
						<ServicesIcon src={supportImg} />
						<ServicesH2>Comprehensive Support</ServicesH2>
						<ServicesP>
							Beyond just capital, we offer strategic guidance,
							operational support, and mentorship to help our
							portfolio companies succeed.
						</ServicesP>
					</ServicesCard>
					<ServicesCard>
						<ServicesIcon src={performanceImg} />
						<ServicesH2>Consistent Performance</ServicesH2>
						<ServicesP>
							Our disciplined investment approach consistently
							delivers strong returns for our stakeholders,
							proving our commitment to excellence.
						</ServicesP>
					</ServicesCard>
				</ServicesWrapper>
			</ServicesContainer>
		</>
	)
}

export default Services
