export const homeObj1 = {
	id: "about",
	lightBg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: "Premium Private Investment",
	headline:
		"Identifying and Nurturing Investment Opportunities across various sectors",
	description:
		"Get access to our team of seasoned professionals that deliver value and drive innovation.",
	buttonLabel: "Get Started",
	showButton: false,
	imgStart: false,
	img: require("../../Images/investment.svg").default,
	alt: "Car",
	dark: true,
	primary: true,
	darkText: false,
}
export const homeObj2 = {
	id: "discover",
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: "Investment Philosophy",
	headline:
		"Thorough research, rigorous due diligence, and a disciplined approach towards risk management.",
	description:
		"We leverage our extensive network and industry insights to identify opportunities that offer strong growth potential and align with our strategic objectives. Partnering with Finapac Capital Pte Ltd (based in Singapore), as well as Greythorn Management and Hatchstone Capital (both based in Australia).",
	buttonLabel: "Learn More",
	showButton: false,
	imgStart: true,
	img: require("../../Images/philosophy.svg").default,
	alt: "Piggybank",
	dark: false,
	primary: false,
	darkText: true,
}
