import React from "react"
import {
	InfoContainer,
	InfoWrapper,
	InfoRow,
	BtnWrap,
	Column1,
	Column2,
	Heading,
	ImgWrap,
	Subtitle,
	TextWrapper,
	TopLine,
	Img,
} from "../Info/InfoElements"

import { Button } from "../../Utilities/ButtonElements"

const InfoSection = ({
	lightBg,
	imgStart,
	topLine,
	lightText,
	darkText,
	headline,
	description,
	buttonLabel,
	showButton,
	alt,
	img,
	id,
	primary,
	dark,
	dark2,
}) => {
	function mailTo() {
		// Encode subject line to handle special characters
		const recipientEmail = "example@gmail.com"
		const emailSubject =
			"Inquiry about Private Investment at W3I Investments"
		const emailBody =
			"Hi W3I Investments, I am writing to inquire about private investment opportunities. \n\n Name: \nPhone Number: \nAge:"

		const encodedSubject = encodeURIComponent(emailSubject)
		const encodedEmailBody = encodeURIComponent(emailBody)
		const mailtoLink = `mailto:${recipientEmail}?subject=${encodedSubject}&body=${encodedEmailBody}`

		window.open(mailtoLink)
	}

	return (
		<>
			<InfoContainer lightBg={lightBg} id={id}>
				<InfoWrapper>
					<InfoRow imgStart={imgStart}>
						<Column1>
							<TextWrapper>
								<TopLine>{topLine}</TopLine>
								<Heading lightText={lightText}>
									{headline}
								</Heading>
								<Subtitle darkText={darkText}>
									{description}
								</Subtitle>
								<br />
								{showButton && (
									<BtnWrap>
										<Button
											to="/"
											smooth={true}
											duration={500}
											spy={true}
											exact={true}
											offset={-80}
											primary={primary ? 1 : 0}
											dark={dark ? 1 : 0}
											dark2={dark2 ? 1 : 0}
										>
											{buttonLabel}
										</Button>
									</BtnWrap>
								)}
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<Img src={img} alt={alt} />
							</ImgWrap>
						</Column2>
					</InfoRow>
				</InfoWrapper>
			</InfoContainer>
		</>
	)
}

export default InfoSection
