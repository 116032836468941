import styled from "styled-components"

export const ServicesContainer = styled.div`
	height: 700px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #010606;

	@media screen and (max-width: 1350px) {
		height: 1000px;
	}

	@media screen and (max-width: 768px) {
		height: 1650px;
	}

	@media screen and (max-width: 480px) {
		height: 1900px;
	}
`

export const ServicesWrapper = styled.div`
	max-width: 1600px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 16px;
	padding: 0 50px;
	margin-bottom: 60px;

	@media screen and (max-width: 1350px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
`

export const ServicesCard = styled.div`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
height: 400px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
transition: all 0.2s ease-in-out;

&:hover {
    transform : scale(1.02);
    tansition: all 0.2 ease-in-out
    cursor: pointer;
}
@media screen and (max-width:1350px) {
    height: 380px;
}

@media screen and (max-width:768px) {
    height: 340px;
}

@media screen and (max-width:480px) {
    height: 400px;
}
`

export const ServicesIcon = styled.img`
	height: 160px;
	width: 160px;
	margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
	font-size: 40px;
	color: #fff;
	margin: 64px 0px;
	align-items: center;
	text-align: center;

	@media screen and (max-width: 480px) {
		font-size: 28px;
	}
`

export const ServicesH2 = styled.h2`
	font-size: 1rem;
	margin-bottom: 10px;
`

export const ServicesP = styled.p`
	font-size: 1rem;
	text-align: center;
`
