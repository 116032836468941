export const contactObj1 = {
	id: "contactUs",
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: "",
	headline: "Contact Us",
	description:
		"We are always looking for new opportunities and partnerships. If you are interested in collaborating with us or learning more about our investment approach, please get in touch.",
	buttonLabel: "Email Us",
	showButton: true,
	imgStart: false,
	img: require("../../Images/contactUs.svg").default,
	alt: "Paper",
	dark: false,
	primary: false,
	darkText: true,
}
