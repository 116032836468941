import React, { useEffect, useState } from "react"
import { FaBars } from "react-icons/fa"
import { animateScroll as scroll } from "react-scroll"
import {
	Nav,
	NavbarContainer,
	NavLogo,
	MobileIcon,
	NavMenu,
	NavItem,
	NavLinks,
	NavBtn,
	NavBtnLink,
	ImgWrap,
	Img,
} from "./NavElements"

const Navbar = ({ toggle }) => {
	const [scrollNav, setScrollNav] = useState(false)

	const changeNav = () => {
		if (window.scrollY >= 80) {
			setScrollNav(true)
		} else {
			setScrollNav(false)
		}
	}
	useEffect(() => {
		window.addEventListener("scroll", changeNav)
	}, [])

	const toggleHome = () => {
		scroll.scrollToTop()
	}

	return (
		<>
			<Nav scrollNav={scrollNav}>
				<NavbarContainer>
					<NavLogo to="/" onClick={toggleHome}>
						<ImgWrap>
							<Img
								src={require("../../Images/logo.svg").default}
								alt={"W3I Investments"}
							/>
						</ImgWrap>
					</NavLogo>
					<MobileIcon onClick={toggle}>
						<FaBars />
					</MobileIcon>
					<NavMenu>
						<NavItem>
							<NavLinks
								to="about"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								About
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to="discover"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								Discover
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to="services"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								Why Choose Us
							</NavLinks>
						</NavItem>
						<NavItem>
							<NavLinks
								to="contactUs"
								smooth={true}
								duration={500}
								spy={true}
								exact="true"
								offset={-80}
							>
								Contact Us
							</NavLinks>
						</NavItem>
					</NavMenu>
					{/* <NavBtn>
                        <NavBtnLink to="/">Sign In</NavBtnLink>
                    </NavBtn> */}
				</NavbarContainer>
			</Nav>
		</>
	)
}

export default Navbar
